<template>
  <div style="position:relative" v-click-afuera="fueraNotificacion">
    <div v-show="ruta=='/chat'" class="notificaciones" :class="{'active': showCampana}" data-toggle="collapse" data-target="#tableroDeNotificacion" aria-expanded="false" aria-controls="tableroDeNotificacion" @click.prevent="quitarCampana()">
      <font-awesome-icon :icon="[(!this.new)?'far':'fas','bell']" />
    </div>
    <div v-show="asesor"  class="notificaciones2" :class="{'active': showCampana}" data-toggle="collapse" data-target="#tableroDeNotificacion" aria-expanded="false" aria-controls="tableroDeNotificacion" @click.prevent="quitarCampana()">     
      <font-awesome-icon :icon="[(!this.new)?'far':'fas','envelope']" />
    </div>
    <div class="collapse" id="tableroDeNotificacion" >
      <ul> 
        <li v-for="(notifica, i) in notifications.data" :key="notifica.id">
            <div class="Notificacion" :id="'notificacion'+i">
              <a v-if="notifica.asesor_notificacion.enlace!=null" target="_blank" :href="notifica.asesor_notificacion.enlace"><div :class="{'bg-danger': notifica.asesor_notificacion.tipo==2, 'bg-success': notifica.asesor_notificacion.tipo==1, 'bg-info': notifica.asesor_notificacion.tipo==0}" class="IcoNot"><font-awesome-icon :icon="[(notifica.asesor_notificacion.categoria!=5)?'far':'fas',(notifica.asesor_notificacion.categoria==1?'hand-paper':notifica.asesor_notificacion.categoria==2?'chart-bar':notifica.asesor_notificacion.categoria==3?'hospital':notifica.asesor_notificacion.categoria==4?'grin-stars':notifica.asesor_notificacion.categoria==5?'paperclip':'bell')]" /></div></a>
              <div v-else :class="{'bg-danger': notifica.asesor_notificacion.tipo==2, 'bg-success': notifica.asesor_notificacion.tipo==1, 'bg-info': notifica.asesor_notificacion.tipo==0, 'bg-warning': notifica.asesor_notificacion.id==0}" class="IcoNot"><font-awesome-icon :icon="[(notifica.asesor_notificacion.categoria!=5)?'far':'fas',(notifica.asesor_notificacion.categoria==1?'hand-paper':notifica.asesor_notificacion.categoria==2?'chart-bar':notifica.asesor_notificacion.categoria==3?'hospital':notifica.asesor_notificacion.categoria==4?'grin-stars':notifica.asesor_notificacion.categoria==5?'paperclip':'bell')]" /></div>
              <div class="texto">
                <div class="d-flex">
                  <b >{{notifica.asesor_notificacion.nombre}}</b>
                  <span >{{notifica.moment}}</span>
                </div>
                <p>{{notifica.asesor_notificacion.descripcion}}</p>
                <span>Enviado por: <i>{{notifica.asesor_notificacion.remitente_nombre}}</i></span>
              </div>
            </div>
        </li>
      </ul>
    </div>
    <div v-if="emergencia" class="notifyEmergente">
      <div class="notificacionEmergente">
        <div class="IcoNot m-auto">
          <font-awesome-icon color="yellow" icon="exclamation-triangle" />
          <div><b >{{this.new.asesor_notificacion.nombre}}</b></div>
        </div>
        
        <p class="px-5">{{this.new.asesor_notificacion.descripcion}}</p>
        <span>De parte de: <i>{{this.new.asesor_notificacion.remitente_nombre}}</i></span>
        
        <div @click="cerrarEmergente()" class="aceptarNot rounded-circle bg-success"><font-awesome-icon color="white" icon="check" /></div>
      </div>
    </div>
  </div>
</template>
<style>
.notificaciones2 {
  font-size: 22px;
  padding-top: 38px;
  padding-bottom: 47px;
  font-size: 38.15px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  margin-right: 80px;
  color: #616161;
  margin-left: 30px;
  left: 200px; }
</style>
<script>
import push from 'push.js'
import SocketIo from 'socket.io-client'
import asesorService from '../services/asesor'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBell, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { faBell as Campana, faCheckCircle, faHandPaper, faChartBar, faHospital, faGrinStars,faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faBell, faCheckCircle, Campana,faHandPaper, faChartBar, faHospital, faGrinStars, faPaperclip,faEnvelope )
export default {
  name:'Avisos',
  data(){
    return{
      ruta: window.location.pathname,
      showCampana:false,
      asesor:false,
      sessionStorageUser: JSON.parse(sessionStorage.getItem('sesion')),
      notifications:[{
        moment:'Hoy',
        asesor_notificacion:{
            nombre:"Estás al día",
            descripcion:"No hay notificaciones actualmente",
            remitente_nombre:"Plataforma Inbox",
            id:0,
            
          },
          
      }],
      emergencia: false,
      noti:false,
      new: {
        asesor_notificacion:{
          nombre:'Sin datos',
          descripcion: 'Ya leiste todo'
        }
      },
    }
  },
  components:{
    FontAwesomeIcon
  },
  mounted(){
    if(this.sessionStorageUser.rol_id==3){
          if(window.location.href.includes('/inicio')){
            this.asesor=true
          }
        }
    let data = {
      query: {
        "Tipo": "4",
        asesorId: this.sessionStorageUser.id == 16 ? null : this.sessionStorageUser.id
      }
    }
    this.socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
    this.socket.on('refreshNotificacion',  (payload) => {
      var moment = require('moment')
      moment.locale('es')

      payload.data.map(x=>{
        x.moment = moment(moment(x.createdAt, 'YYYY-MM-DD HH:mm A').toDate()).fromNow()
      })
      if(payload.data.length!=0){
        this.notifications=payload
      }else{
        this.notifications.data=[{
          moment:'Hoy',
          asesor_notificacion:{
              nombre:"Estás al día",
              descripcion:"No hay notificaciones actualmente",
              remitente_nombre:"Plataforma Inbox",
              id:0,
              
            }
        }]
      }
      
      this.new=payload.nueva
      
      if(this.new){
        this.notificar(payload.nueva.asesor_notificacion.nombre, payload.nueva.asesor_notificacion.descripcion, payload.nueva.notif.id, payload.nueva.asesor_notificacion.enlace)
        this.new.asesor_notificacion.tipo==2?this.emergencia=true:this.emergencia=false
        this.noti=true
      }else{
        this.emergencia=false
        this.noti=false
      }
      this.$forceUpdate
    })
  },
  directives: {
    "click-afuera": {
      bind: function(el, binding) {
          // Define ourClickEventHandler
          const ourClickEventHandler = event => {
          if (!el.contains(event.target) && el !== event.target) {
              // as we are attaching an click event listern to the document (below)
              // ensure the events target is outside the element or a child of it
              binding.value(event); // before binding it
          }
          };
          // attached the handler to the element so we can remove it later easily
          el.__vueClickEventHandler__ = ourClickEventHandler;

          // attaching ourClickEventHandler to a listener on the document here
          document.addEventListener("click", ourClickEventHandler);
      },
      unbind: function(el) {
          // Remove Event Listeners
          document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  },
  methods:{
    fueraNotificacion(){
      var div = document.getElementById("tableroDeNotificacion")
      div.classList.remove('show')
      this.showCampana=false
    },
    cerrarEmergente(){ 
      if(this.new){ 
        this.emergencia=false
        if (this.new.asesor_notificacion.enlace!='')
          window.open(this.new.asesor_notificacion.enlace, '_blank')
        this.new={asesor_notificacion:{
          nombre:'Sin datos',
          descripcion: 'Ya leiste todo'
        }} 
      }
    },
    notificar(a, b, c, d){
      push.create(a, {
        body: b,
        icon: 'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/favicon.png',
        timeout: 8000,
        onClick: function () {
            window.focus();
            this.close();
            asesorService.vistoNotificacion(c)
            window.open(d, '_blank')
        }
      });
    },
    quitarCampana(){
      if(this.new){
        asesorService.vistoNotificacion(this.new.notif.id)
      }
      this.showCampana=!this.showCampana
      this.new=false
      
    },
    abrirLink(link){
      window.open(link, '_blank')
    }
  }
}
</script>